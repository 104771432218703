<template>
    <section>
        <vue-headful title="FLPN Aviasolutions - NaviData Databases"></vue-headful>
        <nav class="level">
            <!-- Left side -->
            <div class="level-left">
                <div class="level-item">
                    <h1 class="title">Navigation Data Databases</h1>
                </div>
            </div>
        </nav>
      <contenedor>
        <b-table
            paginated
            backend-pagination
            backend-sorting
            :data="pagination.records"
            :loading="pagination.loading"
            :total="pagination.total"
            :per-page="pagination.perPage"
            :default-sort-direction="pagination.defaultSortOrder"
            :default-sort="[pagination.sortField, pagination.sortOrder]"
            @page-change="onPageChange"
            @sort="onSort">
            <template slot-scope="props">
                <b-table-column field="schema_name" label="Schema Name" sortable>
                    {{ props.row.schema_name }}
                </b-table-column>
                <b-table-column field="Status" label="Status" sortable>
                    <b-tag type="is-default" v-bind:class="{'is-success': props.row.is_active}"><span>{{getStatus(props.row.is_active)}}</span></b-tag>
                </b-table-column>
                <b-table-column field="created_at" label="Added" sortable>
                    <span>{{ props.row.created_at }}</span>
                </b-table-column>
                <b-table-column width="80" label="Actions">
                    <div class="field has-addons">
                        <p v-if="!props.row.is_active" class="control">
                        <b-tooltip label="Set Active" type="is-dark">
                            <button
                            type="button"
                            class="button is-success is-small"
                            v-on:click="onSetActiveNavdata(props.row)"
                            >
                            Set Active
                            </button>
                        </b-tooltip>
                        </p>
                    </div>
                </b-table-column>
            </template>
        </b-table>
      </contenedor>
    </section>
</template>
<script>
import NavdataDbsService from "../../services/navdata-dbs.service";
import Contenedor from "@/components/Contenedor";

export default {
    name: 'NavdataDbIndex',
  components: {Contenedor},
  data() {
        return {
            navdataDbsService: new NavdataDbsService(),
            pagination: {
                records: [],
                total: 0,
                loading: false,
                sortField: 'created_at',
                sortOrder: 'desc',
                defaultSortOrder: 'desc',
                page: 1,
                perPage: 20
            }
        };
    },
    methods: {
        paginate() {
            const params = {
                sort_field: this.pagination.sortField,
                sort_order: this.pagination.sortOrder,
                per_page: this.pagination.perPage,
                page: this.pagination.page
            };
            this.pagination.loading = true;
            this.navdataDbsService.paginate(params).then(
                ({data}) => {
                    // api.themoviedb.org manage max 1000 pages
                    this.pagination.records = data.data;
                    this.pagination.total = data.meta.total;
                    this.pagination.loading = false
                },
                () => {
                    this.pagination.records = []
                    this.pagination.total = 0
                    this.pagination.loading = false
                    this.$buefy.toast.open({
                        message: 'An error ocurred while paginating!!!',
                        position: 'is-bottom',
                        type: 'is-danger'
                    });
                }
            );
        },
        /*
        * Handle page-change event
        */
        onPageChange(page) {
            this.pagination.page = page;
            this.paginate();
        },
        /*
        * Handle sort event
        */
        onSort(field, order) {
            this.pagination.sortField = field;
            this.pagination.sortOrder = order;
            this.paginate();
        },
        getStatus: function(is_active) {
            if (is_active) {
                return 'ACTIVE';
            }
            return 'INACTIVE';
        },
    onSetActiveNavdata: function(navdataDb) {
      this.$buefy.dialog.confirm({
        title: "Setting Active Navigation Data",
        message: `This will change the active Navigation Data to: </br><b>Schema Name: ${navdataDb.schema_name}</br>Created at: ${navdataDb.created_at}</b></br>Are you sure? (This could take a while)`,
        cancelText: "Cancel",
        confirmText: "Yes, set Active",
        type: "is-info",
        size: "is-medium",
        // width: 600,
        hasIcon: true,
        onConfirm: () => {
          const loadingComponent = this.$buefy.loading.open({container: null})
          this.navdataDbsService.setActive(parseInt(navdataDb.id)).then(
            ({ data }) => {
              loadingComponent.close();
              this.paginate();
              this.$buefy.toast.open({
                message: data.notificacion,
                type: "is-success"
              });
            },
            () => {
              loadingComponent.close();
              this.paginate();
              this.$buefy.toast.open({
                message: "An error ocurred while setting the active Navigation Data Database.",
                type: "is-danger"
              });
            }
          );
        }
      });
    },
    },
    mounted() {
        this.paginate();
    }
}
</script>