import ApiService from "./api.service"

export default class NavdataDbsService extends ApiService
{
    path = 'navdata-dbs';
    paginate(params) {
        return this.http.get(`${this.url}/paginate`, { params: params } );
    }
    
    setActive(navdataId) {
        return this.http.post(`${this.url}/${navdataId}/set-active`);
    }
}